<template>
  <div>
    <goDialogSurveyChains
      v-model="showGoDialogChains"
      :reloadTable="getDataFromApi"
      :row="selectedRow"
      :importExcelChainPerSurveyTemplatePathName="
        importExcelChainPerSurveyTemplatePathName
      "
    />
    <v-dialog v-if="selected" scrollable v-model="showEdit" max-width="700px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-autocomplete
                  v-model="selected.brandId"
                  v-bind:label="$t('brands.brand')"
                  v-bind:placeholder="$t('start_typing_to_search')"
                  item-text="name"
                  item-value="id"
                  :search-input.sync="search_brand"
                  :loading="isBrandSearching"
                  :items="filtered_brands"
                  hide-no-data
                  hide-selected
                  filled
                  required
                  :rules="requiredRules"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-model="selected.name"
                  v-bind:label="$t('brands.name')"
                  required
                  :rules="requiredRules"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  v-model="selected.description"
                  v-bind:label="$t('brands.description')"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-menu
                  ref="date_menu_start"
                  v-model="date_menu_start"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="selected.startDate"
                      v-bind:label="$t('start_date')"
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="selected.startDate"
                    no-title
                    @input="date_menu_start = false"
                    :first-day-of-week="1"
                    :locale="this.$i18n.locale"
                    :reactive="reactive_start"
                    @change="onChangeDates"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-menu
                  ref="date_menu_end"
                  v-model="date_menu_end"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="selected.endDate"
                      v-bind:label="$t('end_date')"
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="selected.endDate"
                    no-title
                    @input="date_menu_end = false"
                    :first-day-of-week="1"
                    :locale="this.$i18n.locale"
                    :reactive="reactive_end"
                    @change="onChangeDates"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  :type="'number'"
                  v-model="selected.limitRecords"
                  v-bind:label="$t('Max Answers')"
                  :rules="numberRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  :type="'number'"
                  v-model="selected.repeatsValue"
                  v-bind:label="$t('Repeat Number')"
                  :rules="numberRules"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-autocomplete
                  v-model="selected.repeatsUnit"
                  v-bind:label="$t('Repeat Frequency')"
                  :items="repeatsUnitValues"
                  item-text="label"
                  item-value="value"
                  filled
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-autocomplete
                  v-model="selected.activeLabel"
                  v-bind:label="$t('status')"
                  :items="statusValues"
                  item-text="label"
                  item-value="value"
                  filled
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-checkbox
                  dense
                  v-model="selected.isRemember"
                  label="Recordar"
                  hide-details="auto"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="12" md="6" class="d-flex align-center">
                <span>Tiempo : </span>
                <b-form-timepicker
                  v-model="selected.estimatedTime"
                  locale="es"
                  style="max-width: 200px;"
                  class="mx-2"
                ></b-form-timepicker>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeEditDialog">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="onSaveClick(selected)"
              :loading="isSaving"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-card elevation="2" outlined shaped tile class="mb-2">
      <v-card-title>
        <div
          class="d-flex justify-content-between align-center"
          style="width: 100%"
        >
          <span>{{ $t("navbar.Surveys") }}</span>
          <v-btn color="primary" dark @click="onItemEdit">
            {{ $t("newitem") }}
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <v-row class="mt-0">
          <v-col cols="12" sm="12" md="12">
            <ve-table
              id="surveys-table"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="tableData"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :scroll-width="1500"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="totaltableData"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import _ from "lodash";
import { logError, logInfo } from "@/utils.js";
import { downloadCSVFile } from "@/csv.js";
import goDialogSurveyChains from "./goDialogSurveyChains.vue";

export default {
  name: "SurveyList",
  components: {
    goDialogSurveyChains
  },
  data: function() {
    return {
      tableData: [],
      totaltableData: 0,
      statusValues: [],
      repeatsUnitValues: [],
      /********** vue-easytable options **********/
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      filterCriteria: {
        brandId: {},
        name: {},
        description: {},
        status: {},
        startDate: {},
        endDate: {},
        limitRecords: {},
        repeatsValue: {},
        repeatsUnit: {}
      },
      filterable_col_list: [
        "brandId",
        "name",
        "description",
        "status",
        "startDate",
        "endDate",
        "limitRecords",
        "repeatsValue",
        "repeatsUnit"
      ],
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        }
      },
      showEmpty: false,
      filterlist_fetch_url: "surveys/getfilterlist",

      /********** form-validation options **********/
      valid: true,
      requiredRules: [v => !!v || "Required"],
      numberRules: [
        // (v) => !!v || "Required",
        v => !v || v >= 0 || "Este campo debe estar por encima de 0"
        // (v) => (v && v <= 100) || "This field should not be above 100",
      ],
      selected: null,
      editedIndex: -1,

      /********** create or edit dialog options **********/
      showEdit: false,
      isSaving: false,
      date_menu_start: false,
      date_menu_end: false,
      reactive_start: false,
      reactive_end: false,
      search_brand: null,
      isBrandSearching: false,
      filtered_brands: [],

      showGoDialogChains: false,
      selectedRow: null,
      importExcelChainPerSurveyTemplatePathName: null
    };
  },
  watch: {
    async search_brand(val) {
      this.search_brand_list(val);
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 || this.editedIndex === undefined
        ? this.$t("newitem")
        : this.$t("edititem");
    },
    col_id() {
      return {
        key: "id",
        title: "ID",
        field: "id",
        align: "left",
        width: 70,
        sortBy: ""
      };
    },
    col_brand() {
      return {
        key: "brandId",
        title: this.$t("brands.brand"),
        field: "brandId",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.brand && row.brand.name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["brandId"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`brandId`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["brandId"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "brandId")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_name() {
      return {
        key: "name",
        title: this.$t("Name"),
        field: "name",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["name"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`name`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["name"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "name")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_description() {
      return {
        key: "description",
        title: `${this.$t("brands.description")}`,
        field: "description",
        align: "left",
        width: 250,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["description"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`description`}
                  isFullText={false}
                  filterCriteria={this.filterCriteria["description"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "description")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_status() {
      return {
        key: "status",
        title: `${this.$t("status")}`,
        field: "status",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.statusLabel}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxesOthers
                  v-model={this.filterCriteria["status"]}
                  dataList={this.statusValues}
                  filterCriteria={this.filterCriteria["status"]}
                  itemvalue="value"
                  itemlabel="label"
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "status")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_startDate() {
      return {
        key: "startDate",
        title: this.$t("start_date"),
        field: "startDate",
        align: "left",
        width: 170,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="date"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["startDate"]["from"]}
                  value={this.filterCriteria["startDate"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="date"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["startDate"]["to"]}
                  value={this.filterCriteria["startDate"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "startDate")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_endDate() {
      return {
        key: "endDate",
        title: this.$t("end_date"),
        field: "endDate",
        align: "left",
        width: 170,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="date"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["endDate"]["from"]}
                  value={this.filterCriteria["endDate"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="date"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["endDate"]["to"]}
                  value={this.filterCriteria["endDate"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "endDate")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_limitRecords() {
      return {
        key: "limitRecords",
        title: this.$t("Max Answers"),
        field: "limitRecords",
        align: "left",
        width: 200,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["limitRecords"]["from"]}
                  value={this.filterCriteria["limitRecords"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["limitRecords"]["to"]}
                  value={this.filterCriteria["limitRecords"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "limitRecords")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_repeatsValue() {
      return {
        key: "repeatsValue",
        title: this.$t("Repeat Number"),
        field: "repeatsValue",
        align: "left",
        width: 200,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["repeatsValue"]["from"]}
                  value={this.filterCriteria["repeatsValue"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["repeatsValue"]["to"]}
                  value={this.filterCriteria["repeatsValue"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "repeatsValue")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_repeatsUnit() {
      return {
        key: "repeatsUnit",
        title: this.$t("Repeat Frequency"),
        field: "repeatsUnit",
        align: "left",
        width: 220,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxesOthers
                  v-model={this.filterCriteria["repeatsUnit"]}
                  dataList={this.repeatsUnitValues}
                  filterCriteria={this.filterCriteria["repeatsUnit"]}
                  itemvalue="value"
                  itemlabel="label"
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "repeatsUnit")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_estimatedTime() {
      return {
        key: "estimatedTime",
        title: this.$t("time"),
        field: "estimatedTime",
        align: "left",
        width: 200,
        sortBy: ""
      };
    },
    col_edit() {
      return {
        key: "edit",
        title: this.$t("salesforce.Edit"),
        field: "actions",
        align: "center",
        width: 100,
        fixed: "right",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <div>
              <v-icon
                medium
                color="primary"
                class="mr-2"
                on-click={() => this.onItemEdit(row)}
              >
                mdi-pencil
              </v-icon>
              <v-icon
                medium
                color="error"
                on-click={() => this.onItemRemove(row.id)}
              >
                mdi-delete
              </v-icon>
            </div>
          );
        }
      };
    },
    col_go() {
      return {
        key: "go",
        title: this.$t("go"),
        field: "go",
        align: "center",
        width: 80,
        fixed: "right",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <div>
              <v-btn
                color="blue darken-1"
                text
                on-click={() => this.onItemGo(row)}
              >
                <b-icon icon="box-arrow-up-right" aria-hidden="true"></b-icon>
              </v-btn>
            </div>
          );
        }
      };
    },
    col_gochains() {
      return {
        key: "chains",
        title: this.$t("chain"),
        field: "chains",
        align: "center",
        width: 80,
        fixed: "right",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <div>
              <v-btn
                color="blue darken-1"
                text
                on-click={() => this.onItemGoChains(row)}
              >
                <b-icon icon="box-arrow-up-right" aria-hidden="true"></b-icon>
              </v-btn>
            </div>
          );
        }
      };
    },
    col_copy() {
      return {
        key: "copy",
        title: this.$t("copy"),
        field: "copy",
        align: "center",
        width: 80,
        fixed: "right",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <div>
              <v-btn
                dark
                color="success darken-1"
                text
                on-click={() => this.onItemCopy(row.id)}
              >
                <b-icon icon="clipboard-plus" aria-hidden="true"></b-icon>
              </v-btn>
            </div>
          );
        }
      };
    },
    columns() {
      return [
        this.col_id,
        this.col_brand,
        this.col_name,
        this.col_description,
        this.col_status,
        this.col_startDate,
        this.col_endDate,
        this.col_limitRecords,
        this.col_repeatsValue,
        this.col_repeatsUnit,
        this.col_estimatedTime,
        this.col_edit,
        this.col_copy,
        this.col_go,
        this.col_gochains
      ];
    }
  },
  methods: {
    async onItemGoChains(row) {
      await this.getDataFromApi();
      this.selectedRow = row;
      this.showGoDialogChains = true;
    },
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },
    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#surveys-table"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      this.filterCriteria[cancelFilterKey] = {};
      this.getDataFromApi();
    },
    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },
    getQueryParams() {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null) {
          if (key === "date") {
            let convert_value = {
              from: "",
              to: ""
            };
            if (
              value["from"] !== "" &&
              value["from"] !== null &&
              value["from"] !== undefined
            ) {
              convert_value["from"] = value["from"];
              filterModel.push({
                columnField: key,
                filterValue: convert_value
              });
            }
            if (
              value["to"] !== "" &&
              value["to"] !== null &&
              value["to"] !== undefined
            ) {
              convert_value["to"] = value["to"];
              filterModel.push({
                columnField: key,
                filterValue: convert_value
              });
            }
          } else if (Object.keys(value).length > 0) {
            filterModel.push({
              columnField: key,
              filterValue: value
            });
          }
        }
      }
      let filter_columns = document.querySelectorAll(
        "#surveys-table .filterable-column"
      );
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map(filter_item => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      if (this.isteam !== undefined) {
        query_params_string += "&isteam=" + this.isteam;
      }
      return query_params_string;
    },
    async getDataFromApi() {
      this.show();
      let query_params_string = this.getQueryParams();
      let fetch_url = "surveys";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      try {
        const resp = await ApiService.get(fetch_url);
        this.tableData = resp.data;
        if (this.tableData.length > 0) {
          this.showEmpty = false;
        } else {
          this.showEmpty = true;
        }
        this.totaltableData = resp.total;
        this.repeatsUnitValues = resp.repeatsUnitValues;
        this.importExcelChainPerSurveyTemplatePathName =
          resp.importExcelChainPerSurveyTemplatePathName;
        this.close();
      } catch (error) {
        this.close();
      }
    },
    async onItemCopy(surveyId) {
      if (window.confirm(`Are you sure you want to copy?`)) {
        this.show();
        try {
          let status = await ApiService.post(`surveys/copydata/${surveyId}`);
          this.close();
          if (status) {
            const { success, message, data } = status;
            if (success) {
              logInfo(this.$t("alert_success"));
              this.getDataFromApi();
            } else {
              logError("Failed !");
            }
          }
        } catch (error) {
          this.close();
          logError("Failed !");
        }
      }
    },
    async onItemRemove(surveyId) {
      console.log(surveyId);
      if (window.confirm(`${this.$t("confirm_delete")}`)) {
        try {
          let status = await ApiService.delete(`surveys/${surveyId}`);
          if (status) {
            const { success } = status;
            if (success) {
              logInfo(this.$t("alert_success"));
              this.getDataFromApi();
            } else {
              logError("Failed !");
            }
          }
        } catch (error) {
          logError("Failed !");
        }
      }
    },
    onItemEdit(item) {
      this.selected = this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.showEdit = true;
    },
    closeEditDialog() {
      this.showEdit = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },
    async onChangeDates() {
      if (this.selected.startDate > this.selected.endDate) {
        this.selected.endDate = this.selected.startDate;
      }
    },
    async search_brand_list(val) {
      let fetch_url = `surveys/getfilterlist?column=selectablebrands&isFullText=true&filterValue=${val}`;
      const resp = await ApiService.get(fetch_url);
      this.filtered_brands = resp.data;
    },
    createSelectedModel(item) {
      let model = {
        id: _.get(item, "id"),
        brandId: _.get(item, "brandId"),
        brand: _.get(item, "brand"),
        name: _.get(item, "name"),
        description: _.get(item, "description"),
        active: _.get(item, "active"),
        startDate: _.get(item, "startDate"),
        endDate: _.get(item, "endDate"),
        limitRecords: _.get(item, "limitRecords"),
        // repeats: _.get(item, "repeats"),
        repeatsUnit: _.get(item, "repeatsUnit"),
        repeatsValue: _.get(item, "repeatsValue"),
        isRemember: _.get(item, "isRemember"),
        estimatedTime: _.get(item, "estimatedTime")
        // deleted: _.get(item, "deleted"),
      };
      if (model.active === undefined) model.active = true;
      if (model.active) {
        model.activeLabel = "active";
      } else {
        model.activeLabel = "inactive";
      }
      this.search_brand_list("");
      return model;
    },
    async onSaveClick(item) {
      if (this.$refs.form.validate()) {
        let body = _.omit(item, ["brand", "activeLabel"]);
        body.active = item.activeLabel === "active" ? true : false;
        try {
          this.isSaving = true;
          let status = null;
          if (body.id) {
            status = await ApiService.put(`surveys/${body.id}`, body);
          } else {
            status = await ApiService.post(`surveys`, body);
          }
          logInfo("éxito");
          this.showEdit = false;
          this.isSaving = false;
          this.getDataFromApi();
        } catch (error) {
          this.showEdit = false;
          this.isSaving = false;
        }
      }
    },
    onItemGo(item) {
      this.$router.push({
        name: "admin_survey_editor",
        params: {
          id: item.id
        }
      });
    },
    init() {
      this.statusValues = [
        { value: "active", label: "Active" },
        { value: "inactive", label: "Inactive" }
      ];
      this.getDataFromApi();
    }
  },

  async mounted() {
    this.init();
  }
};
</script>

<style></style>
